
module.exports = name => {
  let prefix = 'wix';
  let suffix = name;
  if (name.indexOf('wix:') === 0 || name.indexOf('wnp:') === 0) {
    suffix = name.substring(4);
  } else if (name.indexOf('wix-') === 0 || name.indexOf('wnp-') === 0) {
    suffix = name.substring(4);
  }

  return {
    'trace': `${prefix}:trace:${suffix}`,
    'debug': `${prefix}:debug:${suffix}`,
    'info': `${prefix}:info:${suffix}`,
    'error': `${prefix}:error:${suffix}`,
    'warn': `${prefix}:warn:${suffix}`
  }
};
