const debug = require('@wix/debug-no-namespace');
const resolve = require('./name-resolver');
const enableNamespace = require('./enable-namespace');
const assert = require('assert');
const jsonStringifySafe = require('json-stringify-safe');


class DebugLogger {
  constructor(name, requestData = {}) {
    assert(name, 'Name must be provided');
    this._requestData = requestData;
    this._name = name;
    const logKeys = resolve(name);
    this._namespace = logKeys['error'];

    debug.enable(enableNamespace(process.env['DEBUG'], this._namespace));

    ['info', 'debug', 'error', 'trace', 'warn'].forEach(level => {
      const levelNamespace = logKeys[level];
      this[`_${level}`] = logWith(cachedLogger(levelNamespace), this._requestData, levelNamespace);
    });
  }

  withRequest(req) {
    return this.withAspects(req.aspects);
  }

  withAspects(aspects) {
    const requestId = get(aspects, 'web-context.requestId');
    return new DebugLogger(this._name, {
      ...(requestId ? {requestId} : {requestId: undefined}),
      ...this._requestData
    });
  }

  trace(...args) {
    this._trace(args);
  }

  debug(...args) {
    this._debug(args);
  }

  info(...args) {
    this._info(args);
  }

  error(...args) {
    this._error(args);
  }

  warn(...args) {
    this._warn(args)
  }
}

function toKeyValueStringPairs(requestData) {
  return Object.keys(requestData).map(key => `[${key}: ${requestData[key]}]`);
}

function isJsonEnabled() {
  return process.env['JSON_STDOUT'] === 'enabled';
}

const LOGGERS = new Map();
function cachedLogger(key) {
  let logger = LOGGERS.get(key);
  if (!logger) {
    logger = debug(key);
    LOGGERS.set(key, logger);
  }
  return logger;
}

function logWith(logger, requestData, namespace) {
  if (isJsonEnabled()) {
    return argsArray => {
      if (argsArray.length > 1) {
        const last = argsArray.slice().pop();
        if (last instanceof Error) {
          argsArray = [{
            errorData: {
              args: argsArray.slice(0, -1),
              message: last.message,
              name: last.name,
              stack: last.stack
            }
          }];
        } else if (argsArray.every(item => typeof item !== 'object')) {
          argsArray = [argsArray.join(',')];
        } else {
          argsArray = [jsonStringifySafe(argsArray)];
        }
      }

      return logger.apply(logger, argsArray.map(el => {
        return debug.coerce(toJson(el, namespace, requestData))
      }));
    };
  }

  return argsArray => logger.apply(logger, toKeyValueStringPairs(requestData).concat(argsArray).map(el => {
    return debug.coerce(el);
  }));
}

function toJson(param, namespace, requestData) {
  const _appName = process.env['APP_NAME'] || 'APP_NAME_NOT_SET';
  const _hostName = process.env['HOSTNAME'] || 'HOSTNAME_NOT_SET';

  const additionalParams = {
    _appName,
    _hostName,
    _namespace: namespace,
    _requestData: toKeyValueStringPairs(requestData),
    wnp_requestId: requestData.requestId,
    wnp_namespace: namespace,
    timestamp: new Date().toISOString()
  };

  if (param instanceof Object) {
    if (Array.isArray(param)) {
      return jsonStringifySafe({
        ...additionalParams,
        text: jsonStringifySafe(param)
      });
    }

    if (param instanceof Error) {
      return jsonStringifySafe({
        ...additionalParams,
        errorData: {
          message: param.message,
          name: param.name,
          stack: param.stack
        }
      });
    }

    if (param._appName || param._hostName || param._namespace || param._requestData || param.timestamp || Object.keys(param).find(k => k.startsWith('wnp_'))) {
      return jsonStringifySafe({
        ...additionalParams,
        data: param,
        error: {
          message: 'Please don\'t use \'_appName\', \'_hostName\', \'_namespace\', \'_requestData\', \'timestamp\' and params starting with \'wnp_\' in your JSON payload!'
        }
      });
    }

    return jsonStringifySafe({
      ...additionalParams,
      ...param
    });
  }

  return jsonStringifySafe({
    ...additionalParams,
    text: param
  });
}

function get(obj, path) {
  const parts = path.split('.');
  let result = obj;
  while (result !== undefined && parts.length > 0) {
    result = result[parts.shift()];
  }
  return result;
}


module.exports = name => new DebugLogger(name);
module.exports.Logger = DebugLogger;
